import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Header from 'components/Header/Header.jsx'
import Footer from 'components/Footer/Footer.jsx'
import landingPageStyle from 'assets/jss/material-kit-react/views/landingPage.jsx'

import { graphql, useStaticQuery } from 'gatsby'

import ThreeArgumentsSection from '../../elements/threeArgumentsSection/threeArgumentsSection'
import Section from '../../components/Section/Section'
import Content from '../../elements/Content/Content'
import BlogSection from '../../elements/RecentBlogPosts/RecentBlogPosts'
import SpainHolidaysCta from '../../elements/SpainholidaysCta/SpainHolidaysCta'
import PageHeaderFull from '../../components/PageHeaderFull/PageHeaderFull'
import Impressionen from '../../elements/Impressionen/Impressionen'

const FerienhausMogarizas = props => {
  const data = useStaticQuery(graphql`
    query {
      datoCmsFerienhausMogariza{
        slug
        title
        subtitle
        backgroundImage{
          alt
          fluid(maxWidth:1800){
          src
          ...GatsbyDatoCmsFluid_noBase64}}
      
        arguments{
          title
          subtitle
          image{
            alt
            fluid(maxWidth:500){
              src
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
        }
        spainholidayBanner{
          title
          subtitle
          buttonTitle
          buttonLink
        }
        content{
          __typename
          ...on DatoCmsH2{
            heading
          }
          ...on DatoCmsH3{
            heading
          }
          ...on DatoCmsH4{
            heading
          }
          ...on DatoCmsH5{
            heading
          }
          ...on DatoCmsH6{
            heading
          }
          ...on DatoCmsText{
            text
          }
          ...on DatoCmsImage{
            image{
              alt
              fluid(maxWidth:900){
                src
              ...GatsbyDatoCmsFluid }
            }

          }

        }
        impressions{
          title
          subtitle
          images{
            alt
            fluid(maxWidth:1800){
              src
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
        }

      }
    }

  `)


  return (
    <div>
      <Header
        absolute
        light
        SEOtitle={data.datoCmsFerienhausMogariza.title}
        SEOdescription={data.datoCmsFerienhausMogariza.subtitle}
        SEOimage={data.datoCmsFerienhausMogariza.backgroundImage.fluid.src}
        SEOpathname={data.datoCmsFerienhausMogariza.slug}
      />
      <PageHeaderFull
        image={data.datoCmsFerienhausMogariza.backgroundImage.fluid}
        alt={data.datoCmsFerienhausMogariza.backgroundImage.alt}
        subtitle={data.datoCmsFerienhausMogariza.subtitle}

      />
      <ThreeArgumentsSection
        tall
        makeTitleH1
        title={data.datoCmsFerienhausMogariza.title}
        threeArguments={data.datoCmsFerienhausMogariza.arguments}
      />
      <Section innerStyles={{ maxWidth: 705 }} paddingTop={80} paddingBottom={80}>
        <Content
          content={data.datoCmsFerienhausMogariza.content}
        />
      </Section>
      <Impressionen
        title={data.datoCmsFerienhausMogariza.impressions[0].title}
        subtitle={data.datoCmsFerienhausMogariza.impressions[0].subtitle}
        images={data.datoCmsFerienhausMogariza.impressions[0].images}
      />
      <SpainHolidaysCta
        title={data.datoCmsFerienhausMogariza.spainholidayBanner[0].title}
        subtitle={data.datoCmsFerienhausMogariza.spainholidayBanner[0].subtitle}
        buttonTitle={data.datoCmsFerienhausMogariza.spainholidayBanner[0].buttonTitle}
        buttonLink={data.datoCmsFerienhausMogariza.spainholidayBanner[0].buttonLink}

      />
      <BlogSection/>
      <Footer/>
    </div>
  )
}

export default withStyles(landingPageStyle)(FerienhausMogarizas)
